
@media (max-width: 1200px) {

  .title {
    font-size: 36px;
    line-height: 48px;
  }
  
  .subtitle {
    font-size: 18px;
    line-height: 29px;
  }
  
  .txt {
    font-size: 14px;
    line-height: 15px;
  }

  .button-container a {
    padding: 12px 20px;
  }

  .main-infographic {
    width: 440px;
    height: 500px;
  }

  .rectangle {
    max-width: 180px;
  }

  .green-circle {
    width: 47px;
    height: 47px;
  }

  .card-logo{
    width: 50%;
    height: 50%;
    object-fit: contain;
  }

  .card-name{
    font-size: 15px;
    line-height: 18px;
  }
  
  .card-txt {
    font-size: 12px;
    line-height: 16px;
  }

  .dashed-circle {
    width: 250px;
    height: 250px;
  }

  .dashed-circle img{
    width: 41px;
    height: 41px;
    object-fit: contain;
  }

  .card-3{
    right: calc(50% - 90px);
  }

  /* Sections */

  .section-container, 
  .withOverflowingBackground::before, 
  .withOverflowingBackground-4::before, 
  .withOverflowingBackground-1::before {
    height: 650px;
  }

  .section-main {
    margin-right: calc((100vw / 20));
  }
  .section-main-reverse {
    margin-left: calc((100vw / 20));
  }

  .section-icon-wrapper {
    width: 48px;
    height: 48px;
  }

  .section-divider {
    height: 54px;
  }
  
  .section-titles-container {
    width: calc(100% - 88px);
  }
  
  .section-pre-title {
    font-size: 18px;
    line-height: 24px;
  }
  
  .section-title {
    font-size: 26px;
    line-height: 35px;
  }
  
  .section-subtitle {
    font-size: 18px;
    line-height: 24px;
  }
  
  .section-txt {
    font-size: 14px;
    line-height: 25px;
  }

  .img-container {
    margin-right: calc((100vw / 20) * -1);
    margin-left: 0;
  }

  .img-container.reverse {
    margin-right: 0;
    margin-left: calc((100vw / 20) * -1);
  }

  .section-img {
    width: 100%;
    max-width: 600px;
    max-height: 350px;
  }

  .backed-by-img-container{
    width: 150px;
    object-fit: contain;
    flex-direction: column;
    justify-content: flex-start;
    margin: 25px;
  }

  .backed-by-img{
    width: 90px;
    height: 90px;
    object-fit: contain;
  }
}
